<script setup lang="ts">
  import { Capacitor } from '@capacitor/core'
  import { Device } from '@capacitor/device'
  import { onMounted, ref } from 'vue'
  import uuid from 'uuid-random'
  import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera'
  import { fetchCurrentLocationOnce } from '@/services/geolocation'
  import { entityType as TEntity, IQueuePhoto, PhotoTypeEnum, uploadPhotoType } from '@/db/tables'
  import { toBase64 } from '@/utils/base64'
  import devicePhotoTag from '@/utils/device-photo-tag'
  import convert from 'heic2any'

  // State
  const device = ref<'ios' | 'android' | 'web' | 'mac' | 'win'>('web')
  const inputField = ref<HTMLInputElement | null>(null)
  const loading = ref(false)
  // Props
  type Props = {
    photoType: keyof typeof PhotoTypeEnum
    projectId: string
    classButton?: string
    labelButton?: string
    iconButton?: string
    roundedButton?: boolean
    entityType: TEntity
  }
  const { photoType, projectId, classButton, labelButton, entityType, iconButton, roundedButton = true } = defineProps<Props>()

  // Emits
  const emit = defineEmits(['onMedia'])

  // Lifecycle
  onMounted(async () => {
    if (Capacitor.isNativePlatform()) {
      const info = await Device.getInfo()
      device.value = info.platform
    } else {
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.includes('win')) {
        device.value = 'win'
      } else if (userAgent.includes('mac')) {
        device.value = 'mac'
      }
    }
  })

  // Methods
  const handlePhotoCameraIOS = async (image: Photo): Promise<IQueuePhoto> => {
    const { latitude, longitude } = await fetchCurrentLocationOnce()
    const generateID = uuid()
    const formatB64 = `data:image/${image.format};base64,${image?.base64String?.toString()}`
    const uploadPhotoType = await devicePhotoTag('UPLOAD')

    return {
      id: generateID,
      base64: formatB64 as any,
      latitude: `${latitude}`,
      longitude: `${longitude}`,
      photoType,
      photoTypeId: '',
      entityType,
      projectId,
      base64Content: `${image.base64String}`,
      uploadPhotoType,
      type_media: 'IMAGE',
    }
  }

  const getPhotoData = async (file: File, uploadPhotoType: uploadPhotoType): Promise<IQueuePhoto> => {
    const base64: any = await toBase64(file)
    const { latitude, longitude } = await fetchCurrentLocationOnce()
    const generateID = uuid()

    return {
      id: generateID,
      base64: base64,
      latitude: `${latitude}`,
      longitude: `${longitude}`,
      photoType,
      photoTypeId: '',
      entityType,
      projectId,
      base64Content: `${base64.split('base64,')[1]}`,
      uploadPhotoType,
      type_media: 'IMAGE',
    }
  }

  const getPhotoFromHeic = async (blob: Blob, uploadPhotoType: uploadPhotoType): Promise<IQueuePhoto> => {
    const base64: any = await blobToBase64(blob)
    const { latitude, longitude } = await fetchCurrentLocationOnce()
    const generateID = uuid()

    return {
      id: generateID,
      base64: base64,
      latitude: `${latitude}`,
      longitude: `${longitude}`,
      photoType,
      photoTypeId: '',
      entityType,
      projectId,
      base64Content: `${base64.split('base64,')[1]}`,
      uploadPhotoType,
      type_media: 'IMAGE',
    }
  }

  const handleGalleryIOS = async () => {
    const image = await Camera.getPhoto({
      quality: 60,
      allowEditing: false,
      source: CameraSource.Photos,
      resultType: CameraResultType.Base64,
    })

    const data = await handlePhotoCameraIOS(image)
    emit('onMedia', [data])
    if (inputField.value) inputField.value.value = ''
  }

  const handleClick = () => {
    if (inputField.value) inputField.value.click()
  }

  const handleChangeFiles = async (event: any) => {
    loading.value = true
    const files = event?.target?.files as File[]
    if (!files?.length) return
    // Filter and process files
    const mediaFiles = Array.from(files)
    const heicFiles = mediaFiles.filter((e) => e.name.toLowerCase().endsWith('.heic'))
    const uploadPhotoType = await devicePhotoTag('UPLOAD')

    const heicToBlob = await Promise.all(
      heicFiles.map(async (datafile) => {
        const result = await convert({
          blob: datafile,
          quality: 1,
        })
        return result
      })
    )

    const promisesHeic = heicToBlob.map((e) => getPhotoFromHeic(e as Blob, uploadPhotoType))
    const promises = mediaFiles.filter((e) => !e.name.toLowerCase().endsWith('.heic')).map((file) => getPhotoData(file, uploadPhotoType))

    const data = (await Promise.all([...promises, ...promisesHeic])).filter((e) => e !== null)

    emit('onMedia', data)
    if (inputField.value) inputField.value.value = ''
    loading.value = false
  }

  function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }
</script>

<template>
  <Button
    v-if="(Capacitor.isNativePlatform() && device === 'ios') || device === 'mac'"
    v-tooltip.bottom="'Upload'"
    :rounded="roundedButton ?? true"
    :loading="loading"
    :icon="iconButton ?? 'pi pi-upload'"
    :label="labelButton ?? ''"
    :class="'RemoveText ' + (classButton ?? 'p-button-rounded p-button-info')"
    @click="handleGalleryIOS"
  />
  <Button
    v-else
    v-tooltip.bottom="'Upload'"
    :loading="loading"
    style="background-color: black; color: white; border-radius: 5px"
    :rounded="roundedButton ?? true"
    :icon="iconButton ?? 'pi pi-upload'"
    :label="labelButton ?? ''"
    :class="'RemoveText ' + (classButton ?? ' p-button-rounded p-button-info')"
    @click="handleClick"
  />

  <input
    ref="inputField"
    style="display: none"
    type="file"
    accept=".heic, .heif, image/heic, image/heif, .jpeg, .jpg, image/jpeg, .png, image/png, .gif, image/gif, .bmp, image/bmp, .webp, image/webp, .tiff, image/tiff, .svg, image/svg+xml"
    multiple
    @change="handleChangeFiles"
  />
</template>

<style scoped>
  @media (max-width: 577px) {
    .RemoveText {
      font-size: 0px;
    }
  }
</style>
