export const projectProblemsEnUS = {
  problems: 'Problems',
  create: 'CREATE',
  edit: 'EDIT',
  noProblemsYet: 'No problems yet',
  createAProblem: 'Create Problem',
  dialog: {
    problem: {
      close: {
        header: 'Close problem',
        details: 'No photo attached. To close problem add photo(s)',
        assignedto: 'Assigned to',
        date: 'Date',
        description: 'Description',
        uploadImages: 'UPLOAD IMAGES',
      },
    },
  },
}

export const projectProblemsEsMX = {
  problems: 'Problemas',
  create: 'CREAR',
  edit: 'EDITAR',
  noProblemsYet: 'Sin problemas',
  createAProblem: 'Crear problema',
  dialog: {
    problem: {
      close: {
        header: 'Cerrar problema',
        details: 'No se ha adjuntado ninguna foto. Para cerrar el problema añada foto(s)',
        assignedto: 'Assigned to',
        date: 'Date',
        description: 'Description',
        uploadImages: 'SUBIR IMAGENES',
      },
    },
  },
}
