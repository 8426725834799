<script setup lang="ts">
  import { onUnmounted, ref, toRefs, watch, type Ref } from 'vue'
  import { useDebounceFn } from '@vueuse/core'
  import api from '@/services/api'
  import { i18n } from '@/i18n'
  import { IProject } from '@/interfaces/project/project.interface'
  import { useUserStore } from '@/store'

  type IEntity = IProject & {
    customerNumber: string | null
    status: string
    type: string
  }

  type Props = {
    entityId: string
    type: 'lead' | 'project'
  }
  const { entityId, type } = defineProps<Props>()
  const emit = defineEmits(['on-close', 'on-select'])

  const { t } = i18n.global
  const loadingEntities = ref(false)
  const querySearch = ref('')
  const inputSearch = ref('')
  const isProject = type === 'project'
  const entityValues: Ref<IEntity[]> = ref([])
  const searchInput = ref<Ref<HTMLInputElement> | null>(null)
  const searchOnlyMyEntities = ref(true)
  const userStore = useUserStore()
  const { id: UserId, permissions } = toRefs(userStore)

  const debounced = useDebounceFn(async () => {
    if (inputSearch.value.length === 0) {
      handleEmptySearch()
      return
    }
    querySearch.value = inputSearch.value
  }, 500)

  const handleGLobalSearch = async () => {
    await debounced()
  }
  const handleEmptySearch = () => {
    entityValues.value = []
    querySearch.value = ''
  }

  const tabActive = ref(1)
  const handleEntityClick = (payload: IProject) => {
    const confirmAction = confirm(`¿ ${t('projectPunchList.confirmMove')} ${payload.name}?`)
    if (confirmAction) {
      emit('on-select', payload)
      emit('on-close')
    }
  }
  const handleCloseModal = () => emit('on-close')
  const handleFetch = async (url: string) => {
    loadingEntities.value = true
    const { data } = await api.get(url)
    entityValues.value = data.data.results
    loadingEntities.value = false
  }

  watch([tabActive, searchOnlyMyEntities, querySearch, UserId, permissions], async ([tab, searchOnly, query, user, per]) => {
    if (!query?.length) return
    const endpoint = `/Api/v2/GlobalSearch?query=${query}&excludeEntity=${entityId}`
    await handleFetch(endpoint)
  })

  onUnmounted(() => {
    tabActive.value = 1
    querySearch.value = ''
    inputSearch.value = ''
  })
</script>

<template>
  <Dialog
    modal
    :visible="true"
    :header="isProject ? t('projectPunchList.moveProject') : t('projectPunchList.moveLeads')"
    :draggable="false"
    position="top"
    :style="{ width: '50vw', marginTop: '70px' }"
    content-class="p-0"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
    @update:visible="handleCloseModal"
  >
    <div style="width: 100%">
      <div class="flex w-full align-items-center md:justify-content-end justify-content-center pt-0 p-4">
        <div class="relative col-12 flex align-items-center justify-content-center">
          <div class="flex flex-row align-items-center gap-2 p-input-icon-left w-full relative p-input-icon-right">
            <i class="pi pi-search" style="width: fit-content; top: 10px" />
            <InputText ref="searchInput" v-model="inputSearch" type="text" :placeholder="t('globalSearch.search')" class="w-full pr-5" @input="handleGLobalSearch" />
            <i v-if="loadingEntities" class="pi pi-spin pi-spinner absolute" style="right: 30px" />
            <i v-if="querySearch.length > 0" class="pi pi-times absolute" style="right: 10px" @click="handleEmptySearch" />
          </div>
        </div>
      </div>
      <header
        v-if="entityValues.length > 0 && tabActive === 1 && permissions?.searchPermissions?.canSearchMyOwnProjects"
        class="flex w-full align-items-start justify-content-start pt-0 p-4"
      >
        <div class="flex flex-row align-items-center justify-content-center">
          <p class="m-0 p-0">{{ t('globalSearch.searchOnlyMyEntities') }}</p>
          <label class="switch">
            <input type="checkbox" :checked="searchOnlyMyEntities" @change="() => (searchOnlyMyEntities = !searchOnlyMyEntities)" />
            <span class="slider round"></span>
          </label>
        </div>
      </header>
    </div>

    <template v-if="entityValues.length > 0">
      <div class="scrollable-content">
        <div class="flex flex-wrap w-full justify-content-evenly p-4" style="background-color: var(--surface-ground)">
          <div class="grid w-full">
            <div
              v-for="entity in entityValues?.filter((e) => e?.type === (type === 'lead' ? 'Lead' : 'Project'))"
              :key="entity.id"
              class="text-color col-12 md:col-6 lg:col-6"
              @click="handleEntityClick(entity)"
            >
              <article class="card_search_item bg-white border-round-2xl border-1 border-gray-100 w-full h-full" style="max-width: 100%; cursor: pointer">
                <section :style="{ objectFit: 'cover', height: '100px', width: '100%' }">
                  <img v-if="entity.photoUrl" :src="entity.photoUrl" alt="" :style="{ objectFit: 'cover', height: '100px', width: '100%' }" class="border-round" />
                  <div v-else class="border-round w-full h-full flex justify-content-center align-items-center" style="background-color: #e6e6e6">
                    <!-- SVG Placeholder -->
                  </div>
                </section>
                <section class="flex flex-column gap-1 justify-content-between" style="padding: 10px">
                  <p class="w-full text-color font-bold text-sm w-15 white-space-wrap overflow-hidden text-overflow-ellipsis m-0">
                    {{ entity.name }}
                  </p>
                  <p class="w-full text-color text-sm w-15 white-space-wrap overflow-hidden text-overflow-ellipsis m-0">
                    {{ entity.address }}
                  </p>
                  <p class="m-0">#{{ entity?.poNumber ?? entity?.customerNumber }}</p>
                  <p class="m-0">{{ entity?.status }}</p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>
