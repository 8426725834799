export const projectPunchListAddEnUS = {
  success: 'Success',
  punchListCreated: 'Punch list created!',
  punchListUpdate: 'Punch list update!',
  error: 'Error',
  punchList: 'Punch List Name',
  assignToUser: 'Assign to User',
  assignToUserPlaceholder: 'Select User',
  submit: 'SUBMIT',
  loading: 'Loading',
  tasklist: 'Task List Name',
  addTask: 'ADD TASK',
  taskName: 'Task Name',
  label: 'TaskList',
  label_task: 'Task',
  taskCreate: 'Task-name-here',
  punchListCreate: 'Punchlist-name-here',
}

export const projectPunchListAddEsMX = {
  success: 'Éxito',
  taskCreate: 'Nombre-de-task-aquí',
  punchListCreate: 'Nombre-de-punchlist-aquí',
  punchListCreated: 'Lista de pendientes creada!',
  punchListUpdate: 'Punch list actualizada!',
  error: 'Error',
  punchList: 'Nombre de la lista de pendientes',
  assignToUser: 'Asignar a usuario',
  assignToUserPlaceholder: 'Seleccionar usuario',
  submit: 'ENVIAR',
  loading: 'Cargando',
  tasklist: 'Nombre de la lista de tareas',
  addTask: 'AGREGAR TAREA',
  taskName: 'Nombre de la tarea',
  label: 'Lista de tareas',
  label_task: 'Tarea',
}
