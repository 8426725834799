<!-- eslint-disable vue/require-explicit-emits -->
<!-- eslint-disable @typescript-eslint/no-empty-function -->
<script lang="ts" setup>
  import { toRefs } from 'vue'
  import Checkbox from 'primevue/checkbox'
  import api from '@/services/api'
  import PreviewImage from './PreviewImage.vue'
  import GalleryComponent from '@/components/GalleryComponent.vue'
  import { ITask } from '@/interfaces/punch-list/task/task.interface'
  import { ETaskStatus } from '@/enums/punch-list/task/task.enum'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  import { PhotoTypeEnum } from '@/db/tables/PhotoQueue.table'
  import { captureException } from '@sentry/vue'
  import { useToast } from 'primevue/usetoast'
  import { useProjectStore } from '@/store'
  import { storeToRefs } from 'pinia'
  import { i18n } from '@/i18n'
  const { t } = i18n.global

  interface IProps {
    item: ITask
    checkState: boolean | null
    type: 'LIST' | 'COMPLETION'
    isFinalized?: boolean
    photoType?: PhotoTypeEnum
  }

  const props = defineProps<IProps>()
  const toast = useToast()
  const emit = defineEmits(['notApplicable', 'refresh', 'selectedItem', 'takePicture', 'selectPhoto', 'deletetask', 'closeAddTasksDialog', 'onEditTask'])
  const { item, type, checkState } = toRefs(props)
  const projectStore = useProjectStore()
  const { project } = storeToRefs(projectStore)
  const handleErrorSentry = (error: unknown) => {
    captureException(error)
  }
  function setSelected() {
    if (type.value === 'COMPLETION') {
      emit('selectedItem', item.value)
    }
  }
  // type TEditImageTaskListItem = {
  //   image: {
  //     base64: string
  //     base64Content: string
  //     id: string
  //   }
  // }
  const handleUpdateEditImageTaskListItem = (id: string, url: string) => {
    if (item.value.photos?.length) {
      item.value.photos = item.value.photos.map((photo) => {
        if (photo.id === id) {
          const newVariants = photo.variants.map((variant) => {
            if (variant.variant !== 'ORIGINAL') return { ...variant, uri: url }
            return variant
          })
          return {
            ...photo,
            url,
            variants: newVariants,
          }
        }
        return photo
      })
    }
  }

  const handleDeleteImageTask = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this task?')
    if (!confirmDelete) return
    try {
      await api.delete(`/Api/Projects/PunchLists/TaskLists/Tasks/${item.value.id}`)
      emit('refresh')
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: t('projectPunchList.deleteTask'),
        life: 3000,
      })
    } catch (error) {
      handleErrorSentry(error)
    }
  }

  const updatePhotoTaskList = async (id: string, base64Content: string) => {
    try {
      await api.patch(`/Api/Project/${project.value.id}/Photo/${id}`, { base64Content })
    } catch (error) {
      handleErrorSentry(error)
    }
  }

  const handleEditImageTaskListItem = async (image: { photoId: string; base_64: string }) => {
    const cleanBase64 = image.base_64.replace('data:image/png;base64,', '')
    handleUpdateEditImageTaskListItem(image.photoId, image.base_64)
    await updatePhotoTaskList(image.photoId, cleanBase64)
  }

  const handleRemoveImageTaskListItm = (id: string) => {
    item.value.photos = item.value.photos?.filter((photo) => photo.id !== id)
  }

  const handleDeleteImageTaskListItem = async (id: string) => {
    try {
      handleRemoveImageTaskListItm(id)
      await api.delete(`/Api/Project/Photo/${id}`)
    } catch (error) {
      handleErrorSentry(error)
      // console.log(error)
    }
  }

  const handleEditTask = async () => emit('onEditTask')
  const onStateChange = async () => {
    try {
      const newStatus = item.value.status === ETaskStatus.COMPLETED ? ETaskStatus.NOT_COMPLETED : ETaskStatus.COMPLETED
      const payload = {
        status: newStatus,
      }
      await api.patch(`/Api/Projects/PunchLists/TaskLists/Tasks/${item.value.id}/UpdateStatus`, payload)
      item.value.status = newStatus
      emit('refresh')
    } catch (err) {
      captureException(err)
      console.log('Error updating status:', err)
    }
  }
</script>

<template>
  <li class="flex align-items-center">
    <div
      style="min-height: 58px"
      class="flex py-2 gap-2 align-items-center w-full list-task-item"
      :class="{
        'item-not-applicable': checkState === false,
        'item-completed': checkState === true,
        'type-completion': type === 'COMPLETION',
      }"
      @click="setSelected"
    >
      <Checkbox :model-value="item.status === 'COMPLETED'" :binary="true" @update:model-value="onStateChange" />
      <div style="flex: 1">
        <div class="w-full vertical-align-middle m-0" style="pointer-events: none">
          <p class="task_title" style="pointer-events: auto; cursor: pointer; font-weight: bold; color: black" @click.stop="handleEditTask">
            {{ item.title }}
          </p>
        </div>
        <GalleryComponent
          :images="item.photos"
          :class-custom="{
            container: 'container-photos align-items-center h-full gap-1 flex',
            containerPhotos: 'w-full justify-content-end align-items-center h-full',
          }"
          show-detail
          show-edit
          show-delete
          class-container=""
          :limit="2"
          entity-type="Projects"
          @edit-image="handleEditImageTaskListItem"
          @delete-image="handleDeleteImageTaskListItem"
        >
          <template #item="slotProps">
            <div v-if="slotProps.item.url && slotProps.index < 1" style="display: flex; flex: 1; flex-direction: 'row'; height: 30px; align-items: center; padding-bottom: 2px">
              <PreviewImage :url="slotProps.item.url" />
              <p v-if="Array.isArray(item.photos) && item.photos.length > 1" style="padding-left: 3px; font-weight: bold; font-size: 13px">+{{ item.photos.length - 1 }}</p>
            </div>
          </template>
        </GalleryComponent>
        <div style="flex-direction: row; display: flex">
          <p class="task_date">
            {{
              item?.completeByUserName
                ? item?.completeByUserName + ' - ' + useDateFormatUtil(item?.updatedAt, 'MMM D, YYYY h:mm A')
                : useDateFormatUtil(item?.updatedAt, 'MMM D, YYYY h:mm A')
            }}
          </p>
        </div>
      </div>
      <div style="flex-direction: row; display: flex; padding: 10px; gap: 5px">
        <button
          style="
            display: flex;
            background-color: black;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            width: 30px;
            justify-content: center;
            align-items: center;
            height: 30px;
          "
          @click="handleDeleteImageTask"
        >
          <svg width="15px" height="15px" viewBox="0 0 448 512" fill="white" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z"
            />
          </svg>
        </button>
      </div>
    </div>
  </li>
</template>

<style lang="scss" scoped>
  .container-photos {
    align-items: center;
    height: 100%;
    margin: 0;
  }

  .task_title {
    font-size: 15px;
    font-weight: bold;
  }

  .task_date {
    margin-bottom: 0px;
    font-size: 10px;
  }

  .list-task-item {
    border: 1px solid #e5e7eb;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    &.type-completion {
      cursor: pointer;
    }

    &.item-active:not(.type-list):after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 7px;
      background-color: transparent;
      pointer-events: none;
      border: 3px solid #3b82f6;
    }
  }
</style>
<style lang="scss">
  .p-checkbox-unchecked-custom {
    > .p-checkbox-box {
      background: rgba(119, 119, 119, 1);
      border-color: rgba(119, 119, 119, 1);
    }
    &:hover > .p-checkbox-box {
      background: rgba(119, 119, 119, 0.5);
      border-color: rgba(119, 119, 119, 0.5);
    }
  }
  .p-checkbox-checked-custom {
    > .p-checkbox-box {
      background-color: rgba(59, 130, 246, 1) !important;
      border-color: rgba(59, 130, 246, 1) !important;
    }

    &:hover > .p-checkbox-box {
      background: rgba(59, 130, 246, 0.8) !important;
      border-color: rgba(59, 130, 246, 0.8) !important;
    }
  }
</style>
