<script lang="ts" setup>
  import FinalLaborBill from '@/components/laborbill/FinalLaborBill.vue'
  import NewFinalLaborBill from '@/components/laborbill/NewFinalLaborBill.vue'
  import { keysLabor, LaborBillType, NewFinalLaborBillType } from '@/components/laborbill/types'
  import { i18n } from '@/i18n'
  import api from '@/services/api'
  import { useToast } from 'primevue/usetoast'
  import { computed, onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'

  const toast = useToast()

  const laborBill = ref<LaborBillType | NewFinalLaborBillType | null>(null)

  const loading = ref(false)
  const route = useRoute()
  const projectId = ref('')
  const { t } = i18n.global
  async function fetchLaborBill() {
    try {
      loading.value = true
      const response = await api.get(`/Api/Project/${projectId.value}/Documents/Latest/LaborBill`)

      laborBill.value = response?.data?.data
    } catch (err) {
      toast.add({
        life: 3000,
        summary: 'Error obtaining laborbill',
        detail: 'an error occurred while obtaining the labor bill',
        severity: 'error',
      })
      console.log(err)
    } finally {
      loading.value = false
    }
  }

  onMounted(async () => {
    projectId.value = `${route.params.id}`
    await fetchLaborBill()
  })
  const NewFinalLaborBillComputed = computed(() => laborBill.value as NewFinalLaborBillType)
  const FinalLaborBillComputed = computed(() => laborBill.value as LaborBillType)
</script>

<template>
  <div class="w-full flex flex-column align-items-center justify-content-center">
    <div style="width: 100%; max-width: 800px">
      <div v-if="loading">
        <div class="flex align-items-center justify-content-center flex-wrap col gap-2 h-20rem">
          <h6 class="text-center font-bold">{{ t('projectLaborBill.gatheringInformation') }}...</h6>
        </div>
      </div>
      <template v-else>
        <NewFinalLaborBill v-if="laborBill?.type === keysLabor.keywNewFinalLaborBill" :labor-bill="NewFinalLaborBillComputed" :refetch="fetchLaborBill" :project-id="projectId" />

        <FinalLaborBill
          v-else-if="laborBill?.type === keysLabor.keyLaborBill || laborBill?.type === keysLabor.keyFinalLaborBill"
          :labor-bill="FinalLaborBillComputed"
          :project-id="projectId"
          :refetch="fetchLaborBill"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .title-tab {
    min-height: 40px;
  }

  .line-item {
    width: calc(100% / 4);

    &.twoOrMore {
      width: calc(100% / 4 - 1rem);
    }
  }

  .p-divider.p-divider-horizontal::before {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }

  .field-label {
    min-width: 110px;
  }
</style>

<style lang="scss">
  .line-item-break.p-divider {
    > .p-divider-content {
      background-color: #eff3f8;
    }
  }

  .labor-input {
    > input {
      width: 100%;
    }
  }
</style>
