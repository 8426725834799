export const projectPunchListEnUS = {
  punchLists: 'Punch Lists',
  add: 'CREATE',
  enter_puchlist: 'Enter the name of the Punchlist',
  newPunchList: 'Create Punch List',
  editPunchList: 'Edit PunchList',
  punchList: 'Punch List',
  completed: 'Completed',
  percetajeCompleted: 'Completed',
  deletePunchlist: 'Delete Punchlist',
  deleteTasklist: 'Delete Tasklist',
  deleteTask: 'Delete Task',
  notTask: 'No tasks available',
  deletePunchlistDescription: 'Do you want to delete this punchlist?',
  deleteTasklistDescription: 'Do you want to delete this tasklist?',
  deleteTaskDescription: 'Do you want to delete this task?',
  punchlistDeletedSuccessfully: 'Punchlist deleted successfully.',
  tasklistDeletedSuccessfully: 'Tasklist deleted successfully.',
  taskDeletedSuccessfully: 'Task deleted successfully.',
  labelModalCancelButton: 'Cancel',
  labelModalDeleteButton: 'Yes!, Delete',
  completedPunchList: 'COMPLETE LIST',
  taskLists: 'Task Lists',
  taskList: 'ADD TASK LIST',
  imageRequired: 'You should have at least 1 photo to complete the task.',
  task: 'ADD TASK',
  startByAddingTaskList: 'Start by adding a task list.',
  newTaskList: 'New Task List',
  name: 'Name',
  create: 'Create',
  loading: 'Loading',
  newTask: 'New Task',
  editTask: 'Edit Task',
  photoRequired: 'Photo Required',
  enter_task_name: 'Enter a Task...',
  noPunchlistsYet: 'No Punch Lists Have Been Created',
  moveProject: 'Move to Project',
  moveLeads: 'Move to Lead',
  selected: 'Selected',
  options: 'Options',
  delete: 'Delete',
  confirmMove: 'Are you sure you want to move this image to',
  imagesDeleted: 'Images have been removed successfully',
  imagesMoved: 'Moving images to ',
  questionDelete: 'Are you sure you want to delete these photos?',
}

export const projectPunchListEsMX = {
  options: 'Opciones',
  confirmMove: 'Estás seguro de que deseas mover esta imagen a',
  questionDelete: '¿Seguro que deseas eliminar estas fotos?',
  imagesMoved: 'Imagenes movidas a ',
  imagesDeleted: 'Se ha eliminado las imagenes correctamente',
  moveLeads: 'Mover a Lead',
  punchLists: 'Lista de pendientes',
  add: 'CREAR',
  delete: 'Borrar',
  selected: 'Selecionadas',
  moveProject: 'Mover a Proyecto',
  editTask: 'Editar Tarea',
  enter_task_name: 'Escribe una tarea...',
  enter_puchlist: 'Escribe el nombre de la Punchlist',
  editPunchList: 'Editar PunchList',
  notTask: 'No hay tareas disponibles',
  deletePunchlist: 'Eliminar Lista de pendientes',
  deleteTasklist: 'Eliminar Lista de Tareas',
  deleteTask: 'Eliminar Tarea',
  labelModalCancelButton: 'Cancelar',
  labelModalDeleteButton: 'Si!, Eliminar',
  deletePunchlistDescription: 'Quieres eliminar esta lista de pendientes?',
  deleteTasklistDescription: 'Quieres eliminar esta lista de tareas?',
  deleteTaskDescription: 'Quieres eliminar esta tarea?',

  punchlistDeletedSuccessfully: 'Lista de pendientes eliminada con éxito.',
  tasklistDeletedSuccessfully: 'Lista de tareas eliminada con éxito.',
  taskDeletedSuccessfully: 'Tarea eliminada con éxito.',

  newPunchList: 'Crear Lista de Pendientes',
  punchList: 'Lista de Pendientes',
  completed: 'Completado',
  percetajeCompleted: 'Completado',
  completedPunchList: 'COMPLETAR LISTA',
  taskLists: 'Lista de Tareas',
  taskList: 'AGREGAR LISTA DE TAREAS',
  imageRequired: 'Debes tener al menos 1 foto para completar la tarea.',
  task: 'AGREGAR TAREA',
  startByAddingTaskList: 'Comienza agregando una lista de tareas.',
  newTaskList: 'Nueva Lista de Tareas',
  name: 'Nombre',
  create: 'Crear',
  loading: 'Cargando',
  newTask: 'Nueva Tarea',
  photoRequired: 'Foto Requerida',
  noPunchlistsYet: 'No se han creado listas de pendientes',
}
