<script setup lang="ts">
  import { i18n } from '@/i18n'
  import { useBreakpointsStore } from '@/store'
  import { imgUrlToBlobBase64 } from '@/utils/imageUrlToBase64'
  import CommentsComponents from '@/views/projects/project-media/comments/CommentsComponents.vue'
  import CommentsComponentsGallery from '@/views/projects/project-media/comments/CommentsComponentsGallery.vue'
  import { Capacitor } from '@capacitor/core'
  import { Directory, Filesystem } from '@capacitor/filesystem'
  import { Share } from '@capacitor/share'
  import { useSwipe } from '@vueuse/core'
  import { storeToRefs } from 'pinia'
  import Dialog from 'primevue/dialog'
  import { useScreenOrientation } from '@vueuse/core'
  import { computed, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch } from 'vue'
  import DialogTagsWeb from './DialogTagsWeb.vue'
  import DialogTagsMobile from './DialogTagsMobile.vue'
  import { IPhoto } from '@/interfaces/photo/photo.interface'
  import { useDateFormatUtil } from '@/utils/useDateFormatUtil'
  import Editor from './editor_images/Editor.vue'
  import { IPhotoFeed } from './PhotoFeedMedia/PhotoFeedMediaImageComponent.vue'
  import GalleryPreRenderImage from './GalleryPreRenderImage.vue'
  const typePhoto = {
    PROGRESS_PICTURES: 'photoType.PROGRESS_PICTURES',
    PREPRODUCTION_PICTURES: 'photoType.PREPRODUCTION_PICTURES',
    TASK: 'photoType.TASK',
    TASK_LIST: 'photoType.TASK_LIST',
    TASK_AFTER: 'photoType.TASK_AFTER',
    TASK_BEFORE: 'photoType.TASK_BEFORE',
    TASK_PICTURES: 'photoType.TASK_PICTURES',
    FINAL_PICTURES: 'photoType.FINAL_PICTURES',
    PROFILE_PICTURES: 'photoType.PROFILE_PICTURES',
    PROBLEM: 'photoType.PROBLEM',
  }

  interface Props {
    images: any[]
    cleanImages?: boolean
    loadingNextImages?: any
    showDetail?: boolean
    showComments?: boolean
    activeComment?: boolean
    showEdit?: boolean
    showDelete?: boolean
    showAddTag?: boolean
    classCustom?: {
      containerPhotos?: string
      container?: string
      item?: string
      footer?: string
    }
    classContainer?: string
    showFavorite?: boolean
    showCheckbox?: boolean
    limit?: number
    entityType: 'Leads' | 'Projects'
  }

  interface Image {
    id: string
  }

  const { t } = i18n.global
  const props = withDefaults(defineProps<Props>(), {
    loadingNextImages: false,
    showAddTag: false,
    showComments: false,
    showDelete: false,
    showEdit: false,
    cleanImages: false,
    showFavorite: false,
    showCheckbox: false,
    classContainer: 'masonry-item-container col col-6 sm:col-4 md:col-3 lg:col-3 xl:w-full lg-item-container lg-item relative p-1',
    classCustom: undefined,
    limit: undefined,
  })
  const { classCustom } = toRefs(props)
  const emit = defineEmits(['next', 'deleteImage', 'editImage', 'updateFavorite', 'close', 'updatePhotoTags', 'update:images', 'select:images'])

  const breakpointStore = useBreakpointsStore()
  const { md } = storeToRefs(breakpointStore)
  const el = ref(null)
  const zoomed: any = ref(false)
  const galleryRef: any = ref(null)
  const deleteImageDialogIsActive = ref(false)
  const isopenComments = ref(false)
  const { isSupported, orientation } = useScreenOrientation()
  const isLandscape = ref(orientation.value?.includes('landscape') ?? false)
  const photoSelect = ref<IPhoto | null>(null)
  const selectedImages = ref<string[]>([])

  watch(
    () => props.cleanImages,
    (newValue) => {
      if (newValue) {
        selectedImages.value = []
      }
    }
  )

  const images = computed({
    get: () => props.images,
    set: (value) => {
      emit('update:images', value)
    },
  })

  const handleOnSelection = (imageId: string) => {
    if (selectedImages.value.includes(imageId)) {
      selectedImages.value = selectedImages.value.filter((id) => id !== imageId)
    } else {
      selectedImages.value.push(imageId)
    }
    emit('select:images', selectedImages.value)
  }

  const handleCloseDeleteDialog = () => {
    deleteImageDialogIsActive.value = false
  }

  const handleOpenDialog = () => {
    deleteImageDialogIsActive.value = true
  }
  const deleteImageFuntion = () => {
    emit('deleteImage', `${props.images[activeIndex.value]?.id}`)
    handleCloseDeleteDialog()
  }

  const setFavorite = (projectPhotoId: string, isFavorite: boolean) => {
    emit('updateFavorite', { id: `${projectPhotoId}`, isFavorite })
  }

  const openDialogTags = () => {
    dialogTags.value = true
    photoSelect.value = images.value[activeIndex.value]
  }

  const { direction } = useSwipe(el, {
    passive: false,
    onSwipeEnd() {
      if (!zoomed.value) {
        if (!props.loadingNextImages) {
          if (direction.value === 'left' && activeIndex.value === props.images.length - 1) {
            activeIndex.value = 0
          } else {
            if (direction.value === 'left' && activeIndex.value < props.images.length - 1) {
              activeIndex.value += 1
              if (props.images.length - 5 === activeIndex.value) {
                emit('next')
              }
            }
          }
        }

        if (direction.value === 'right' && activeIndex.value === 0) {
          activeIndex.value = props.images.length
        }
        if (direction.value === 'right' && activeIndex.value > 0) {
          activeIndex.value -= 1
        }
        galleryRef?.value?.reset()
      }
    },
  })

  const activeIndex = ref(0)
  const loadImage = ref(false)
  const responsiveOptions = ref([
    {
      breakpoint: '1300px',
      numVisible: 4,
    },
    {
      breakpoint: '575px',
      numVisible: 1,
    },
  ])
  const displayCustom = ref(false)
  const openEditImage = ref(false)
  const dialogTags = ref(false)
  const imageSelect = ref<{ photoId: string; photoOURL: string }>({
    photoId: '',
    photoOURL: '',
  })
  const handleSaveEditor = (photo: { photoId: string; base_64: string }) => {
    emit('editImage', photo)
    openEditImage.value = false
  }
  const handleCloseEditor = () => {
    openEditImage.value = false
  }
  const updateTags = (photo: IPhoto) => {
    console.log('photo', photo)
    console.log('images.value', images.value[activeIndex.value])
    images.value = images.value.map((image) => {
      if (image.id === photo.id) {
        console.log('image', image)
        return photo
      }
      return image
    })
    dialogTags.value = false
    emit('updatePhotoTags', photo)
  }

  const handleShowComments = () => {
    isopenComments.value = !isopenComments.value
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const handleShareImage = async () => {
    const { publicStorage } = await Filesystem.requestPermissions()

    if (!publicStorage) return

    const image = images.value[activeIndex.value]

    let imageName, data

    if (image.url.startsWith('http')) {
      imageName = image.url.substring(image.url.lastIndexOf('/') + 1)

      data = await imgUrlToBlobBase64(image.url)
    } else {
      imageName = `${Date.now()}.${image.url.substring('data:image/'.length, image.url.indexOf(';base64'))}`

      data = image.url
    }

    try {
      await Filesystem.writeFile({
        path: imageName,
        data: data as string,
        directory: Directory.Cache,
      })

      const { uri: url } = await Filesystem.getUri({
        directory: Directory.Cache,
        path: imageName,
      })

      await Share.share({
        url,
        title: `IHS Production - ${new Date().toISOString()}`,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const openEdit = async () => {
    const image = images.value[activeIndex.value] as IPhotoFeed

    imageSelect.value = {
      photoOURL: image?.variants?.find((v) => v.variant === 'ORIGINAL')?.uri ?? '',
      photoId: image?.id,
    }
    openEditImage.value = true
  }

  const imageClick = (index: number) => {
    activeIndex.value = index
    displayCustom.value = true
    // errorWithImage.value = false
  }

  const handleCloseGallery = () => {
    displayCustom.value = false
    errorWithImage.value = false
  }

  const changeIndex = () => {
    galleryRef.value.reset()
    loadImage.value = false
    errorWithImage.value = false
    if (!props.loadingNextImages) {
      if (props.images.length - 5 === activeIndex.value) {
        emit('next')
      }
    }
  }

  const next = () => {
    errorWithImage.value = false
    if (activeIndex.value === props.images.length - 1) {
      activeIndex.value = 0
    } else {
      if (activeIndex.value < props.images.length - 1) {
        activeIndex.value += 1
        if (props.images.length - 5 === activeIndex.value) {
          emit('next')
        }
      }
    }
  }

  const prev = () => {
    errorWithImage.value = false
    if (activeIndex.value === 0) {
      activeIndex.value = props.images.length
    } else if (activeIndex.value > 0) {
      activeIndex.value -= 1
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    errorWithImage.value = false
    if (event.key === 'Escape') {
      handleCloseGallery()
    }

    if (event.key === 'ArrowLeft') {
      prev()
    }
    if (event.key === 'ArrowRight') {
      next()
    }
  }

  const zoomIn = () => {
    galleryRef.value.zoomIn()
  }

  const zoomOut = () => {
    galleryRef?.value?.zoomOut()
  }
  watch(displayCustom, () => {
    if (displayCustom.value) {
      window.addEventListener('keydown', handleKeyDown)
      if (md.value) {
        setTimeout(() => {
          const next = document.getElementsByClassName('p-galleria-item-next')[0]
          if (next?.classList) {
            next?.classList?.remove?.('p-galleria-item-next')
            next?.classList?.add?.('hidden')
          }
        }, 0)
      }

      setTimeout(() => setupZoomer(), 1)
    } else {
      emit('close')

      zoomer.isSet = false

      // smooth scroll to: <ImageComponent id=photo.id />
      document.getElementById(`${images.value[activeIndex.value]?.id}`)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  const zoomer = reactive({
    dimention: { width: '0px', height: '0px' },
    isSet: false,
    imgAspectRatio: 1,
  })

  function setupZoomer() {
    const { width, height } = window.getComputedStyle(document.getElementById('zommer-container') as HTMLElement)
    const floatWidth = parseFloat(width)
    const floatHeight = parseFloat(height)

    zoomer.dimention.width = (floatWidth - 96).toFixed() + 'px'
    zoomer.dimention.height = (floatHeight - 32).toFixed() + 'px'

    zoomer.isSet = true
  }

  function handleLoadImage(event: Event) {
    errorWithImage.value = false
    loadImage.value = true

    const img = event.target as HTMLImageElement
    zoomer.imgAspectRatio = img.naturalWidth / img.naturalHeight
  }

  const errorWithImage = ref(false)

  watch([isSupported, orientation], () => {
    if (isSupported.value) {
      console.log('orientation', orientation.value)
      isLandscape.value = (orientation.value ?? '').includes('landscape')
      console.log('isLandscape', !md && !isLandscape.value)
      document.addEventListener('click', handleDocumentClick)
    }
  })

  const handleError = () => {
    errorWithImage.value = true
    loadImage.value = false
  }

  onMounted(() => {
    errorWithImage.value = false
    if (props.activeComment) {
      displayCustom.value = true
      if (!md.value) handleShowComments()
    }
  })

  const handleDocumentClick = () => {
    const elementContainer = document.querySelectorAll('[data-pc-section="mask"]')

    elementContainer.forEach(function (element) {
      element.addEventListener('click', function (event) {
        if (event.target === element) {
          handleCloseGallery()
        }
      })
    })
  }
  onMounted(() => {
    document.addEventListener('click', handleDocumentClick)
  })
  onBeforeUnmount(() => {
    document.removeEventListener('click', handleDocumentClick)
  })
  const videoViewUrl = ref<string | null>(null)
  const videoModalView = ref(false)
  const handleSetViewUrl = (url: string) => {
    videoModalView.value = true
    videoViewUrl.value = url
  }
  const handleClose = () => {
    videoModalView.value = false
    videoViewUrl.value = null
  }
</script>

<template>
  <slot
    name="commentsMobile"
    :info="{
      isopen: isopenComments,
      photoId: (images ?? []).length > 0 ? `${images[activeIndex]?.id}` : '',
      closeComments: () => {
        isopenComments = !isopenComments
      },
    }"
  >
    <CommentsComponents
      v-if="isopenComments"
      :photo-id="`${images[activeIndex]?.id}`"
      @close-comments="
        () => {
          isopenComments = !isopenComments
        }
      "
    />
  </slot>
  <Dialog
    v-model:visible="videoModalView"
    modal
    header=""
    :style="{ width: '100vw', height: '100vh' }"
    :pt="{
      root: {
        style: {
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
        },
      },
      header: {
        style: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
          justifyContent: 'end',
          background: 'black',
        },
      },
      content: {
        style: {
          height: '100%',
          width: '100%',
          background: 'black',
        },
      },

      closeButton: {
        style: {
          background: '#000',
          color: '#fff',
        },
      },
    }"
    @update:visible="() => handleClose()"
  >
    <div
      :style="{
        height: '100%',
        width: '100%',
        background: 'black',
      }"
    >
      <video
        controls
        :style="{
          height: '100%',
          width: '100%',
        }"
        preload="auto"
        autoplay
        controlsList="nodownload"
      >
        <source :src="`${videoViewUrl}`" type="video/mp4" />
      </video>
    </div>
  </Dialog>
  <div class="flex flex-column justify-content-center" :class="classCustom?.containerPhotos">
    <Galleria
      v-model:active-index="activeIndex"
      v-model:visible="displayCustom"
      v-model:value="images"
      :responsive-options="responsiveOptions"
      :num-visible="10"
      :container-style="md && isLandscape ? 'width: 80vw; height: 100%; ' : 'width: 100vw'"
      :container-class="md ? 'web-container' : ''"
      :circular="true"
      :full-screen="true"
      :show-thumbnails="false"
      !:transition-interval="0"
      :pt="{
        closeButton: {
          class: 'p-galleria-close-custom',
          style: {
            display: isLandscape ? 'none' : 'flex',
          },
        },
        footer: {
          style: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            overflow: 'hidden',
            paddingLeft: '12px',
            width: '100%',
          },
        },
      }"
      @update:active-index="changeIndex"
    >
      <!-- button mobile in footer -->
      <template #footer>
        <div v-if="!md || !isLandscape" class="aditional-buttons flex gap-2">
          <Button
            v-if="showFavorite"
            :icon="`pi ${images[activeIndex]?.isFavorite ? 'pi-star-fill' : 'pi-star'}`"
            severity="secondary"
            class="button-size-web"
            rounded
            @touchstart="setFavorite(images[activeIndex]?.id, !images[activeIndex].isFavorite)"
            @click="setFavorite(`${images[activeIndex]?.id}`, !images[activeIndex].isFavorite)"
          />
          <Button
            v-if="images[activeIndex]?.mediaType === 'IMAGE'"
            icon="pi pi-search-plus"
            severity="secondary"
            class="button-size-web"
            rounded
            @click="zoomIn"
            @touchstart="zoomIn"
          />
          <Button
            v-if="images[activeIndex]?.mediaType === 'IMAGE'"
            icon="pi pi-search-minus"
            severity="secondary"
            class="button-size-web"
            rounded
            @click="zoomOut"
            @touchstart="zoomOut"
          />

          <Button
            v-if="showDetail && showEdit && images[activeIndex]?.mediaType === 'IMAGE'"
            icon="pi pi-pencil"
            severity="secondary"
            class="button-size-web"
            rounded
            @click="openEdit"
            @touchstart="openEdit"
          />
          <Button
            v-if="showDetail && showDelete"
            icon="pi pi-trash"
            severity="secondary"
            class="button-size-web"
            rounded
            @click="handleOpenDialog"
            @touchstart="handleOpenDialog"
          />
          <Button v-if="showDelete && showAddTag" icon="pi pi-tag" severity="secondary" class="button-size-web" rounded @click="openDialogTags" @touchstart="openDialogTags" />
          <Button
            v-if="showDelete && showComments"
            icon="pi pi-comments"
            severity="secondary"
            class="button-size-web"
            rounded
            @click="handleShowComments"
            @touchstart="handleShowComments"
          />
          <Button
            v-if="Capacitor.isNativePlatform()"
            icon="pi pi-share-alt"
            severity="secondary"
            class="button-size-web"
            rounded
            @click="handleShareImage"
            @touchstart="handleShareImage"
          />
        </div>
      </template>
      <template #item="slotProps">
        <!-- mobile -->
        <div v-if="slotProps.item?.mediaType === 'VIDEO'" style="" class="py-10 flex flex-column align-items-center justify-content-center absolute">
          <Button @click="handleSetViewUrl(slotProps.item.url)">
            <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_249_83)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.1568 8.52857C10.2048 8.12158 10.3472 7.73144 10.5726 7.3892C10.7981 7.04697 11.1003 6.76211 11.4553 6.55731C11.8102 6.35251 12.2081 6.23343 12.6172 6.20955C13.0263 6.18567 13.4354 6.25766 13.8118 6.41978C15.7145 7.23319 19.9787 9.1664 25.3895 12.2893C30.8021 15.4139 34.6094 18.1427 36.2631 19.3807C37.675 20.4396 37.6786 22.5394 36.2649 23.6019C34.6273 24.8327 30.8666 27.5256 25.3895 30.6897C19.907 33.8538 15.693 35.7637 13.8082 36.5664C12.1849 37.2597 10.3682 36.208 10.1568 34.4576C9.90952 32.4115 9.44727 27.7657 9.44727 21.4913C9.44727 15.2204 9.90772 10.5764 10.1568 8.52857Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_249_83">
                  <rect width="43" height="43" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Button>
        </div>
        <div v-if="!md || !isLandscape" ref="el" class="flex flex-column align-items-center py-10">
          <div id="zommer-container" style="height: 75vh; max-height: 75vh" class="py-10 w-full flex flex-column align-items-center justify-content-center">
            <template v-if="!slotProps.item?.url?.endsWith('mov')">
              <span v-if="!loadImage" class="w-full flex justify-content-center">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem" />
              </span>
            </template>

            <video
              v-if="slotProps.item?.url?.endsWith('mov')"
              id="videoGallery"
              controls
              :style="{
                width: '80%',
                height: '80%',
              }"
              autoplay
            >
              <source :src="`${slotProps.item?.url}`" type="video/mp4" />
            </video>
            <template v-if="!slotProps.item?.url?.endsWith('mov')">
              <v-zoomer
                v-if="zoomer.isSet"
                ref="galleryRef"
                v-model:zoomed="zoomed"
                :aspect-ratio="zoomer.imgAspectRatio"
                :double-click-to-zoom="false"
                pivot="image-center"
                :mouse-wheel-to-zoom="false"
                :zooming-elastic="true"
                :style="{
                  width: '100%',
                  height: zoomer.dimention.height,
                }"
              >
                <GalleryPreRenderImage :url="slotProps.item?.variants?.[2]?.uri ?? slotProps.item?.url" @error="handleError" @load="handleLoadImage" />
              </v-zoomer>
            </template>
          </div>
          <!-- detail info image mobile  -->
          <div v-if="showDetail" class="flex flex-row mt-10" style="color: #ffffff; padding: 12px; width: 100vw">
            <img
              :src="`https://contacts.zoho.com/file?ID=${slotProps.item?.user?.id}&fs=thumb`"
              alt="user media"
              width="47px"
              height="47px"
              class="border-circle mr-2"
              style="height: 47px; width: 47px"
            />
            <div class="flex flex-column">
              <p class="font-bold text-md m-0 text-left">{{ slotProps.item?.user?.fullName ?? '' }}</p>
              <p class="text-xs m-0 text-left">
                {{ t(typePhoto[(slotProps.item?.type as keyof typeof typePhoto) ?? 'PROGRESS_PICTURES'] ?? typePhoto.PROGRESS_PICTURES) }}
              </p>
              <p class="text-xs m-0 text-left" style="color: rgba(255, 255, 255, 0.7)">
                {{ useDateFormatUtil(slotProps.item?.createdTime, 'MMM D, YYYY h:mm A') }}
              </p>
            </div>
          </div>
        </div>
        <!-- desktop -->
        <div v-else ref="el" class="grid w-full" style="height: 95vh">
          <button type="button" class="p-galleria-item-prev p-galleria-item-nav p-link" @click="prev" @touchstart="prev">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="p-icon p-galleria-item-prev-icon"
              aria-hidden="true"
              data-pc-section="previousitemicon"
            >
              <path
                d="M9.61296 13C9.50997 13.0005 9.40792 12.9804 9.3128 12.9409C9.21767 12.9014 9.13139 12.8433 9.05902 12.7701L3.83313 7.54416C3.68634 7.39718 3.60388 7.19795 3.60388 6.99022C3.60388 6.78249 3.68634 6.58325 3.83313 6.43628L9.05902 1.21039C9.20762 1.07192 9.40416 0.996539 9.60724 1.00012C9.81032 1.00371 10.0041 1.08597 10.1477 1.22959C10.2913 1.37322 10.3736 1.56698 10.3772 1.77005C10.3808 1.97313 10.3054 2.16968 10.1669 2.31827L5.49496 6.99022L10.1669 11.6622C10.3137 11.8091 10.3962 12.0084 10.3962 12.2161C10.3962 12.4238 10.3137 12.6231 10.1669 12.7701C10.0945 12.8433 10.0083 12.9014 9.91313 12.9409C9.81801 12.9804 9.71596 13.0005 9.61296 13Z"
                fill="currentColor"
              ></path>
            </svg>
            <span class="p-ink"></span>
          </button>
          <div
            id="zommer-container"
            class="col flex relative align-items-center justify-content-center background-gallery-dark border-round-left-xl"
            style="height: 100%; padding: 0px"
          >
            <div class="next-buttons-web">
              <button type="button" class="p-galleria-item-nav p-link" @click="next" @touchstart="next">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="p-icon p-galleria-item-next-icon"
                  aria-hidden="true"
                  data-pc-section="nextitemicon"
                >
                  <path
                    d="M4.38708 13C4.28408 13.0005 4.18203 12.9804 4.08691 12.9409C3.99178 12.9014 3.9055 12.8433 3.83313 12.7701C3.68634 12.6231 3.60388 12.4238 3.60388 12.2161C3.60388 12.0084 3.68634 11.8091 3.83313 11.6622L8.50507 6.99022L3.83313 2.31827C3.69467 2.16968 3.61928 1.97313 3.62287 1.77005C3.62645 1.56698 3.70872 1.37322 3.85234 1.22959C3.99596 1.08597 4.18972 1.00371 4.3928 1.00012C4.59588 0.996539 4.79242 1.07192 4.94102 1.21039L10.1669 6.43628C10.3137 6.58325 10.3962 6.78249 10.3962 6.99022C10.3962 7.19795 10.3137 7.39718 10.1669 7.54416L4.94102 12.7701C4.86865 12.8433 4.78237 12.9014 4.68724 12.9409C4.59212 12.9804 4.49007 13.0005 4.38708 13Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span class="p-ink"></span>
              </button>
            </div>
            <div class="aditional-buttons-web flex gap-2" :style="showDetail ? 'left: calc(var(--sal) + 46px);' : 'left: calc(var(--sal) + 35px);'">
              <div style="display: flex; gap: 0.2rem; justify-content: center; align-items: center">
                <Button
                  v-if="showFavorite"
                  :icon="`pi ${images[activeIndex]?.isFavorite ? 'pi-star-fill' : 'pi-star'}`"
                  severity="secondary"
                  style="background-color: rgba(169, 169, 169, 0.1); border: none; border-radius: 5px"
                  @click="setFavorite(`${images[activeIndex]?.id}`, !images[activeIndex].isFavorite)"
                  @touchstart="() => setFavorite(`${images[activeIndex]?.id}`, !images[activeIndex].isFavorite)"
                />
                <Button
                  v-if="showDetail && showEdit && slotProps.item?.mediaType === 'IMAGE'"
                  severity="secondary"
                  icon="pi pi-pencil"
                  style="background-color: rgba(169, 169, 169, 0.1); border: none; border-radius: 5px"
                  @click="openEdit"
                  @touchstart="openEdit"
                />
                <Button
                  v-if="showDetail && showDelete"
                  severity="secondary"
                  icon="pi pi-trash"
                  style="background-color: rgba(169, 169, 169, 0.1); border: none; border-radius: 5px"
                  @click="handleOpenDialog"
                  @touchstart="handleOpenDialog"
                />
                <Button
                  v-if="slotProps.item?.mediaType === 'IMAGE'"
                  icon="pi pi-search-plus"
                  severity="secondary"
                  style="background-color: rgba(169, 169, 169, 0.1); border: none; border-radius: 5px"
                  @click="zoomIn"
                  @touchstart="zoomIn"
                />
                <Button
                  v-if="slotProps.item?.mediaType === 'IMAGE'"
                  icon="pi pi-search-minus"
                  severity="secondary"
                  style="background-color: rgba(169, 169, 169, 0.1); border: none; border-radius: 5px"
                  @click="zoomOut"
                  @touchstart="zoomOut"
                />
                <Button
                  v-if="!showComments"
                  severity="secondary"
                  icon="pi pi-times"
                  style="background-color: rgba(169, 169, 169, 0.1); border: none; border-radius: 5px"
                  @click="displayCustom = false"
                  @touchstart="displayCustom = false"
                />
              </div>
            </div>
            <div
              class="border-round-left-xl"
              :style="{
                height: '100%',
                width: '100%',
                display: 'flex',
                backgroundColor: 'black',
                justifyContent: 'center',
                alignItems: 'center',
              }"
            >
              <video
                v-if="slotProps.item?.url?.endsWith('mov')"
                controls
                :style="{
                  width: '80%',
                  height: '80%',
                }"
                preload="auto"
                autoplay
              >
                <source :src="`${slotProps.item?.url}`" type="video/mp4" />
              </video>
              <template v-if="!slotProps.item?.url?.endsWith('mov')">
                <v-zoomer
                  v-if="zoomer.isSet"
                  ref="galleryRef"
                  v-model:zoomed="zoomed"
                  :zooming-elastic="true"
                  :aspect-ratio="zoomer.imgAspectRatio"
                  :style="{
                    width: '80%',
                    height: '80%',
                  }"
                >
                  <GalleryPreRenderImage :url="slotProps.item?.variants?.[2]?.uri ?? slotProps.item?.url" @error="handleError" @load="handleLoadImage" />
                </v-zoomer>
              </template>
            </div>
          </div>
          <main
            v-if="showComments"
            class="container-tags h-full px-0 border-round-right-xl flex flex-column p-2"
            style="max-width: 300px; width: 100%; display: grid; grid-template-rows: 1fr 1fr 1fr"
          >
            <header class="flex flex-column gap-2 p-3">
              <div class="flex flex-row mt-10">
                <div class="flex justify-content-between w-full">
                  <div class="flex">
                    <img
                      :src="`https://contacts.zoho.com/file?ID=${slotProps.item?.user?.id}&fs=thumb`"
                      alt="user media"
                      width="47px"
                      height="47px"
                      class="border-circle mr-2"
                      style="height: 47px; width: 47px"
                    />
                    <div class="flex flex-column">
                      <p class="font-bold text-md m-0 text-left">{{ slotProps.item?.user?.fullName ?? '' }}</p>
                      <p class="text-xs m-0 text-left">
                        {{ t(typePhoto[(slotProps.item?.type as keyof typeof typePhoto) ?? 'PROGRESS_PICTURES'] ?? typePhoto.PROGRESS_PICTURES) }}
                      </p>
                      <p class="text-xs m-0 text-left">
                        {{ useDateFormatUtil(slotProps.item?.createdTime, 'MMM D, YYYY h:mm A') }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <i class="pi pi-times cursor-pointer" @click="displayCustom = false" @touchstart="displayCustom = false"></i>
                  </div>
                </div>
              </div>
              <div class="w-full flex-wrap p-1" style="max-width: 300px; padding-top: 20px">
                <p class="font-bold text-base pt-4">{{ t('galleryComponent.titleTags') }}</p>
                <Button v-if="showAddTag" :label="t('galleryComponent.addTag')" text @click="openDialogTags" />
                <ul
                  v-if="(slotProps?.item?.tags ?? []).length > 0"
                  class="scroll-container m-0 w-full flex list-none gap-2 pl-0 p-0"
                  :style="{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    overflow: 'hidden',
                    margin: '10px',
                  }"
                >
                  <li v-for="tag in slotProps?.item?.tags ?? []" :key="tag.tagId" class="p-1 border-round-md h-fit flex" style="align-items: center; background-color: #000000">
                    <i class="pi pi-tag" style="color: #ffffff; padding-left: 5px" />
                    <p style="white-space: nowrap; color: #ffffff; font-weight: 600; padding-right: 5px; padding-left: 5px">{{ tag.name }}</p>
                  </li>
                </ul>
              </div>
            </header>
            <slot
              name="commentsWeb"
              :info="{
                photoId: `${slotProps.item?.id}`,
              }"
            >
              <CommentsComponentsGallery :photo-id="`${slotProps.item?.id}`" :entity-type="props?.entityType" />
            </slot>
          </main>
        </div>
      </template>
    </Galleria>

    <div v-if="images && !openEditImage" class="grid w-full template-image" :class="classCustom?.container">
      <div v-for="(image, index) in Number(props.limit) ? images?.slice(0, Number(props.limit)) : images" :key="`${image?.id ?? index}`" :class="classContainer" class="relative">
        <span v-if="showFavorite || showCheckbox" style="z-index: 1; position: absolute; padding: 5px; cursor: pointer; top: 5px">
          <template v-if="showCheckbox">
            <label class="custom-checkbox">
              <input type="checkbox" :checked="selectedImages.includes(image?.id)" @change="handleOnSelection(image?.id)" />
              <span class="checkmark" />
            </label>
          </template>
          <template v-else>
            <i :class="`pi ${image.isFavorite ? 'pi-star-fill' : 'pi-star'}`" style="font-size: 1.5rem; color: #ffc400" @click="setFavorite(`${image?.id}`, !image.isFavorite)"></i>
          </template>
        </span>
        <div class="w-full h-full" @click="showCheckbox ? handleOnSelection(image?.id) : imageClick(index)">
          <slot name="item" :item="image" :index="index"></slot>
        </div>
      </div>
    </div>
    <slot v-if="!openEditImage" name="default"></slot>
  </div>
  <Editor
    v-if="openEditImage"
    :available-annotations="true"
    :photoid="imageSelect?.photoId"
    :photourl="imageSelect?.photoOURL"
    @close="handleCloseEditor"
    @save="handleSaveEditor"
  />
  <!-- <EditImageIndex v-if="openEditImage && imageSelect" :photo="imageSelect" @close-edit="(value) => handleEditImage(value?.image)" /> -->
  <Dialog v-model:visible="deleteImageDialogIsActive" class="w-full m-0" style="max-width: 42rem" :modal="true" :draggable="false">
    <template #header>
      <h5>{{ t('galleryComponent.dialog.removePhoto') }}</h5>
    </template>
    <div class="flex flex-column gap-2">
      <div style="font-size: 1rem; font-weight: 600; margin-bottom: 0.5rem">
        {{ t('galleryComponent.dialog.removePhotoDescription') }}
      </div>
      <div class="flex flex-row gap-2">
        <Button :label="t('galleryComponent.dialog.removePhotoCancel')" class="p-button-text" @click="handleCloseDeleteDialog" @touchstart="handleCloseDeleteDialog" />
        <Button :label="t('galleryComponent.dialog.removePhotoConfirm')" class="p-button-danger" @click="deleteImageFuntion" @touchstart="deleteImageFuntion" />
      </div>
    </div>
  </Dialog>
  <Dialog v-model:visible="dialogTags" :show-header="true" class="w-full m-0" style="max-width: 42rem" :modal="true" :draggable="false">
    <template #header>
      <label for="assignToTag" style="font-weight: bold; font-size: larger"> Select Tags</label>
    </template>
    <DialogTagsWeb v-if="photoSelect && md" :photo="photoSelect" @update-photo-tags="updateTags" />
    <DialogTagsMobile v-else-if="photoSelect" :photo="photoSelect" @update-photo-tags="updateTags" />
  </Dialog>
</template>

<style>
  .custom-checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  .custom-checkbox input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }

  .custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 4px;
    transition: all 0.3s ease;
  }

  .custom-checkbox input:checked + .checkmark {
    background-color: black;
    border-color: black;
  }

  .custom-checkbox input:checked + .checkmark::after {
    content: '✔';
    color: white;
    font-size: 20px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .custom-button {
    background: transparent;
    border: none;
    color: inherit;
    font: inherit;
    padding: 0;
    margin: 0;
    cursor: pointer;
    text-align: left;
    outline: none;
  }
  .custom-button:focus {
    outline: none;
  }
  .container-tags {
    background-color: #f7f7f7;
  }
  .scroll-container {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  .p-galleria-close-custom {
    top: calc(var(--sat) + 15px) !important;
    right: calc(var(--sar) + 12px) !important;
  }
  .aditional-buttons {
    z-index: 1050;
    bottom: calc(var(--sab) + 15px);
    left: calc(var(--sal) + 12px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .aditional-buttons-web {
    z-index: 1050;
    position: absolute;
    top: calc(var(--sab) + 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .next-buttons-web {
    z-index: 1050;
    position: absolute;
    top: calc(var(--sab) + 50%);
    right: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .masonry-item-container {
    height: 160px;
  }

  .template-image {
    @media screen and (min-width: 1200px) {
      display: grid !important;
      /* gap: 8px; */
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
  }

  .lg-item-container {
    @media screen and (min-width: 1200px) {
      flex: 0 0 auto;
      padding: 0.5rem;
      width: 160px;
    }
  }

  .p-galleria-close {
    z-index: 1050;
  }

  .p-galleria-item-prev {
    z-index: 1050;
  }

  .border-icon {
    border-radius: 50%;
    color: white;
    background-color: gray;
    padding: 8px;
  }

  .p-galleria-mask {
    --maskbg: rgba(0, 0, 0, 0.95) !important;
  }

  .zoomer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .p-galleria-item-prev {
    left: 10% !important;
  }

  .web-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .web-container > .p-galleria-content {
    width: 100%;
  }

  .web-container > .p-galleria-footer {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .web-container > .p-galleria-close {
    filter: brightness(3);
    top: 2.5% !important;
    right: 10%;
    color: #181818;
  }

  .p-galleria-close {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    top: 10px;
    right: 0%;
  }

  .background-gallery-dark {
    background-color: #151515 !important;
  }

  .button-size-web {
    width: 2.5rem;
    height: 2.5rem;
  }
</style>
<style lang="scss" scoped>
  .grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0rem;
    margin-left: 0rem;
    margin-top: 0rem;
  }
</style>
