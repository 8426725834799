<script lang="ts" setup>
  import { onMounted, ref, watch } from 'vue'
  import { useToast } from 'primevue/usetoast'
  import { useProjectStore, useUserStore } from '@/store'
  import api from '@/services/api'
  import { i18n } from '@/i18n'
  import AutoComplete from 'primevue/autocomplete'
  import Avatar from 'primevue/avatar'
  import Button from 'primevue/button'
  import InputText from 'primevue/inputtext'
  import { IAddTask } from '@/interfaces/punch-list/task/task.interface'
  import { IUser } from '@/interfaces/user/user.interface'
  import { captureException } from '@sentry/vue'
  import { Capacitor } from '@capacitor/core'
  import { Device } from '@capacitor/device'
  import processMediaInputAndGetMediaIds from '@/utils/proccessMediaInputAndGetIds'

  const { t } = i18n.global
  const projectStore = useProjectStore()
  const { fetchProjectPunchList } = projectStore

  const toast = useToast()
  const isSubmitting = ref<boolean>(false)
  const punchListNameFieldValue = ref<string | null>(null)
  const punchListAssignToUserFieldValue = ref<IUser | null>(null)
  const punchListAssignToUserSuggestions = ref<IUser[]>([])
  const punchListTaskListFieldValue = ref<string | null>(null)
  const punchListTasks = ref<IAddTask[]>([])
  const addNewTaskDisabled = ref<boolean>(false)

  const props = defineProps({
    projectId: { type: String, required: true },
    isEditing: { type: Boolean, required: false, default: false },
    payload: { type: Object, required: false, default: null },
  })

  const { id, fullName, email, profile, photoUrl, phoneNumber, crewCompanyId, zuid } = useUserStore()
  const emit = defineEmits(['closeAddDialog'])
  onMounted(() => {
    if (props.isEditing) {
      punchListNameFieldValue.value = props.payload?.name
      punchListAssignToUserFieldValue.value = props.payload?.assignedTo
    }
  })

  const updatePunchList = async () => {
    if (!punchListAssignToUserFieldValue.value) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'A user must be assigned', life: 3000 })
      isSubmitting.value = false
      return
    }
    const payload = {
      id: props?.payload?.id,
      name: punchListNameFieldValue.value,
      status: props?.payload?.status,
      description: '',
      assignedToId: punchListAssignToUserFieldValue.value?.id,
    }
    await api.patch('/Api/Projects/PunchLists', payload)
    toast.add({ severity: 'success', detail: t('projectPunchListAdd.punchListUpdate'), life: 3000 })
    punchListNameFieldValue.value = ''
    emit('closeAddDialog')
  }

  const createPunchList = async () => {
    addNewTaskDisabled.value = true
    try {
      if (!punchListAssignToUserFieldValue.value) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'A user must be assigned', life: 3000 })
        isSubmitting.value = false
        return
      }
      isSubmitting.value = true
      const tasks = await Promise.all(
        punchListTasks.value.map(async (punch) => {
          const mediaIds = await processMediaInputAndGetMediaIds(punch.photos)
          return {
            title: `${punch.title}`,
            photoIds: mediaIds,
          }
        })
      )
      const payload = {
        name: punchListNameFieldValue.value,
        description: null,
        assignedToId: punchListAssignToUserFieldValue.value.id,
        projectId: props.projectId,
        taskLists: [{ title: punchListTaskListFieldValue.value, instructions: null, details: null, tasks }],
      }

      await api.post('/Api/Projects/PunchListTree', payload)
      await fetchProjectPunchList(props.projectId)
      emit('closeAddDialog')
      toast.add({ severity: 'success', summary: t('projectPunchListAdd.success'), detail: t('projectPunchListAdd.punchListCreated'), life: 3000 })
      isSubmitting.value = false
    } catch (e) {
      captureException(e)
      toast.add({ severity: 'error', summary: t('projectPunchListAdd.error'), detail: `${e}`, life: 3000 })
      isSubmitting.value = false
      addNewTaskDisabled.value = false
    }
  }
  const handlePunchList = async () => await (props.isEditing ? updatePunchList() : createPunchList())
  const searchUsersToAssignToPunchList = async ($event: any) => {
    const term = $event.query
    const defaultUser = {
      id: id,
      email: email,
      zuid: zuid,
      profile: profile,
      fullName: fullName,
      phone: phoneNumber,
      photoUrl: photoUrl ?? '',
      crewCompany: crewCompanyId,
    }
    const users = await getUsersToAssignPunchList(term)
    const filteredUsers = users.filter((user) => user.id !== defaultUser.id && user.fullName !== defaultUser.fullName)
    punchListAssignToUserSuggestions.value = [defaultUser, ...filteredUsers]
  }

  const getUsersToAssignPunchList = async (searchTerm: string): Promise<IUser[]> => {
    const {
      data: { data: resp },
    } = await api.get(`/Api/Projects/${props.projectId}/Users/PunchList?&query=${searchTerm}`)
    return resp
  }

  watch(punchListTasks.value, (oldValue, newValue) => {
    let isDisabled = false
    for (const task of newValue) {
      if (!task.title || task.title.length === 0) {
        isDisabled = true
        break
      }
      if (task.photos.length === 0) {
        isDisabled = true
        break
      }
    }
    addNewTaskDisabled.value = isDisabled
  })

  const device = ref<'ios' | 'android' | 'web' | 'mac' | 'win'>('web')
  onMounted(async () => {
    if (Capacitor.isNativePlatform()) {
      const info = await Device.getInfo()
      device.value = info.platform
    }

    if (!Capacitor.isNativePlatform()) {
      const userAgent = navigator.userAgent.toLowerCase()
      const isWindows = userAgent.includes('win')
      if (isWindows) device.value = 'win'
      const isMac = userAgent.includes('mac')
      if (isMac) device.value = 'mac'
    }
  })
  const handleChangePunchlistName = (value: string | undefined) => {
    punchListTaskListFieldValue.value = `${value} - ${t('projectPunchListAdd.label')}  `
  }
</script>

<template>
  <div class="flex flex-column gap-3">
    <div class="flex flex-column gap-1">
      <label for="assignToUser">{{ t('projectPunchListAdd.assignToUser') }}</label>
      <AutoComplete
        v-model="punchListAssignToUserFieldValue"
        class="w-full"
        option-label="fullName"
        :placeholder="t('projectPunchListAdd.assignToUserPlaceholder')"
        :dropdown="true"
        :suggestions="punchListAssignToUserSuggestions"
        @complete="searchUsersToAssignToPunchList($event)"
      >
        <template #item="slotProps">
          <div class="flex justify-content-start align-items-center">
            <div>
              <Avatar :image="`https://contacts.zoho.com/file?ID=${slotProps.item.zuid}&fs=thumb`" class="mr-2" size="large" shape="circle" />
            </div>
            <div>
              <div class="ml-2 text-base">{{ slotProps.item.fullName }}</div>
              <div class="ml-2 text-muted text-sm">
                {{ slotProps.item.profile }}
                <span v-if="slotProps.item.crewCompany"> - {{ slotProps.item.crewCompany }}</span>
              </div>
            </div>
          </div>
        </template>
      </AutoComplete>
    </div>

    <div class="flex flex-column gap-1">
      <label for="name">{{ $t('projectPunchListAdd.punchList') }}</label>
      <InputText id="name" v-model="punchListNameFieldValue" type="text" class="p-inputtext w-full" @update:model-value="handleChangePunchlistName" />
    </div>

    <div class="flex justify-content-end">
      <Button
        :label="props.isEditing ? t('projectProblems.edit') : t('projectProblems.create')"
        class="w-full"
        :loading="isSubmitting"
        :disabled="!punchListNameFieldValue || punchListNameFieldValue.length === 0 || !punchListAssignToUserFieldValue || isSubmitting"
        @click="handlePunchList"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @media (max-width: 595px) {
    .ButtonInputImage {
      font-size: 0 !important;
    }
  }

  .list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .p-divider.p-divider-horizontal::before {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
</style>
