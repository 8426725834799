import { i18n } from '@/i18n'

export const menuItemsMedia = (type: 'project' | 'lead', handleMenuOption: (option: string) => void) => {
  const { t } = i18n.global
  const isProject = type === 'project'
  return [
    {
      label: t('projectPunchList.options'),
      items: [
        {
          label: isProject ? t('projectPunchList.moveProject') : t('projectPunchList.moveLeads'),
          icon: 'pi pi-sync',
          command: () => handleMenuOption('move'),
        },
        {
          label: t('projectPunchList.delete'),
          icon: 'pi pi-trash',
          command: () => handleMenuOption('delete'),
        },
      ],
    },
  ]
}
