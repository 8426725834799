<!-- eslint-disable vue/no-mutating-props -->
<script lang="ts" setup>
  import { onMounted, ref, watch, type Ref } from 'vue'
  import { useToast } from 'primevue/usetoast'
  import api from '@/services/api'
  import Button from 'primevue/button'
  import { i18n } from '@/i18n'
  import InputText from 'primevue/inputtext'
  import { IAddTask } from '@/interfaces/punch-list/task/task.interface'
  import { captureException } from '@sentry/vue'
  import { base64ToBlob } from '@/utils/base64Toblob'
  import devicePhotoTag from '@/utils/device-photo-tag'
  import UploadButtonComponentVue from '@/components/UploadButtonComponent.vue'
  import { IQueuePhoto, newIQueuePhoto, QueueStatusType, addNewIQueuePhotosCamera, deleteIQueuePhoto } from '@/db/tables'
  import PhotoList from '@/components/PhotosList/PhotoList.vue'
  import { fetchCurrentLocationOnce } from '@/services/geolocation'
  import uploadNewQueuePhoto from '@/composables/uploadQueuePhoto'
  type Iprops = {
    payload?: IAddTask[]
    isEditing?: boolean
    projectId: string
    taskListId: string
  }
  const props = defineProps<Iprops>()
  const toast = useToast()
  const { t } = i18n.global
  const isSubmitting = ref(false)
  const punchListTasks: Ref<IAddTask[]> = ref([{ title: null, instructions: null, details: null, isPhotoRequired: true, photos: [] }])
  const addNewTaskDisabled = ref(true)
  const queueStore = uploadNewQueuePhoto()
  const { uploadQueuePhotos } = queueStore

  const emit = defineEmits(['closeAddTasksDialog', 'refresh'])
  onMounted(() => {
    if (props.isEditing) {
      punchListTasks.value[0].title = props.payload?.title
    } else {
      punchListTasks.value[0].title = ''
    }
  })

  const uploadPhotos = async (photos: IQueuePhoto[], newTaskID: string) => {
    const { latitude, longitude } = await fetchCurrentLocationOnce()
    const uploadPhotoType = await devicePhotoTag('UPLOAD')
    const info = photos.map((photo) => {
      const blob = base64ToBlob(`${photo.base64}`, 'image/jpeg')
      const photoData: newIQueuePhoto = {
        id: photo.id,
        projectId: photo.projectId,
        entityName: '',
        latitude: latitude ?? '',
        longitude: longitude ?? '',
        photoType: photo.photoType,
        entityType: 'Project',
        photoTypeId: newTaskID,
        file: blob,
        status: 'IDLE' as QueueStatusType,
        date: new Date(),
        errorMessage: '',
        retries: 0,
        uploadPhotoType,
        poNumberProject: '',
      }
      return { ...photoData }
    })
    await addNewIQueuePhotosCamera(info)
    await uploadQueuePhotos()
  }

  const updateTask = async () => {
    const values = punchListTasks.value[0]
    isSubmitting.value = true
    addNewTaskDisabled.value = true
    const response = await api.patch('/Api/Projects/PunchLists/TaskLists/Tasks', {
      id: props.payload?.id,
      taskListId: props.taskListId,
      title: values.title,
      instructions: '',
      details: '',
      isPhotoRequired: false,
      photoIds: [],
    })
    const newTaskID = response?.data?.data?.id
    uploadPhotos(punchListTasks.value[0].photos, newTaskID)
    isSubmitting.value = false
    emit('closeAddTasksDialog')
    toast.add({ severity: 'success', summary: 'Success', detail: 'Successfully update task', life: 3000 })
  }

  const createTask = async () => {
    try {
      const values = punchListTasks.value[0]
      isSubmitting.value = true
      addNewTaskDisabled.value = true
      const response = await api.post('/Api/Projects/PunchLists/TaskLists/Tasks', {
        details: '',
        photoIds: [],
        instructions: '',
        isPhotoRequired: false,
        taskListId: props.taskListId,
        title: values.title,
      })
      const newTaskID = response?.data?.data?.id
      uploadPhotos(punchListTasks.value[0].photos, newTaskID)
      isSubmitting.value = false
      emit('closeAddTasksDialog')
      toast.add({ severity: 'success', summary: 'Success', detail: 'Successfully added task', life: 3000 })
    } catch (e) {
      captureException(e)
      toast.add({ severity: 'error', summary: 'Error', detail: `${e}`, life: 3000 })
      isSubmitting.value = false
      addNewTaskDisabled.value = false
    }
  }

  const handleTask = async () => await (props.isEditing ? updateTask() : createTask())
  watch(punchListTasks.value, (_, newValue) => {
    let isDisabled = false
    for (const task of newValue) {
      if (!task.title || task.title.length === 0) {
        isDisabled = true
        break
      }

      if (task.photos.length === 0) {
        isDisabled = true
        break
      }
    }

    addNewTaskDisabled.value = isDisabled
  })
  const handleImages = (currentIndex: number, images: IQueuePhoto[]) => {
    const activeTaskPhotos = punchListTasks.value[currentIndex]?.photos || []
    punchListTasks.value[currentIndex].photos = [...images, ...activeTaskPhotos]
  }
  const handleRemoveImageInTask = async (index: number, id: string) => {
    const task = punchListTasks.value[index]
    const photos = task.photos?.filter((e) => e?.id !== id)
    task.photos = photos
    if (props.payload?.photos) {
      props.payload.photos = props.payload?.photos.filter((e: { id: string }) => e?.id !== id)
      await api.delete(`/Api/Project/Photo/${id}`)
    }
  }
</script>

<template>
  <div class="flex flex-column gap-3">
    <ul class="flex flex-column gap-4 p-0 m-0">
      <li v-for="(task, taskIndex) in punchListTasks" :key="`task-${taskIndex}`" class="flex flex-column gap-3">
        <div class="flex flex-column gap-2">
          <InputText v-model="task.title" :placeholder="t('projectPunchList.enter_task_name')" type="text" class="p-inputtext flex-grow-1" />
          <div class="flex flex-row align-items-center w-full">
            <UploadButtonComponentVue
              style="background-color: black"
              class-button="p-button-text ButtonInputFile ButtonInputImageTask"
              entity-type="Project"
              photo-type="TASK_BEFORE"
              :project-id="props.projectId"
              @on-media="(images) => handleImages(taskIndex, images)"
            />
          </div>
          <div v-if="props.isEditing">
            <PhotoList :photos="[...(task.photos ?? []), ...(payload?.photos ?? [])]" @remove="(id) => handleRemoveImageInTask(taskIndex, id)" />
          </div>
          <div v-else>
            <PhotoList :photos="task.photos" @remove="(id) => handleRemoveImageInTask(taskIndex, id)" />
          </div>
        </div>
      </li>
    </ul>
    <div class="flex justify-content-end">
      <Button :label="props.isEditing ? t('projectProblems.edit') : t('projectProblems.create')" class="w-full" :loading="isSubmitting" @click="handleTask" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @media (max-width: 595px) {
    .ButtonInputImageTask {
      font-size: 0 !important;
    }
  }
  .list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .p-divider.p-divider-horizontal::before {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
</style>
