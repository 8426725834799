const keywNewFinalLaborBill = 'NewFinalLaborBill'
const keyFinalLaborBill = 'FinalLaborBill'
const keyLaborBill = 'LaborBill'

export const keysLabor = {
  keywNewFinalLaborBill,
  keyFinalLaborBill,
  keyLaborBill,
}

export type ITypesLaborBill = typeof keywNewFinalLaborBill | typeof keyFinalLaborBill | typeof keyLaborBill

export type LaborBillType = {
  info: {
    companyLogoDarkUrl: string | null
    companyLogoUrl: string | null
    customerAddressLine1: string | null
    customerAddressLine2: string | null
    customerName: string | null
    customerNumber: string | null
    detailAreaOfWork: string | null
    detailDumpster: string | null
    detailGutterProblems: string | null
    detailIceDamArea: string | null
    detailLeakingArea: string | null
    detailPreferredTimeline: string | null
    detailRoofAccess: string | null
    detailRoofingProblems: string | null
    detailSatelliteDish: string | null
    // items: '[{"description":"Test iPad 0115 1","laborCost":"218","quantity":3,"laborCostTotal":654},{"description":"Test iPad 0114","laborCost":"18","quantity":35,"laborCostTotal":630},{"description":"Test iOS 0114 1","laborCost":"18","quantity":26,"laborCostTotal":468},{"description":"Test android 2","laborCost":"25.80","quantity":2,"laborCostTotal":51.6},{"description":"Test2 ","laborCost":"0.80","quantity":28,"laborCostTotal":22.400000000000002},{"description":"Test android","laborCost":"2888.28","quantity":17,"laborCostTotal":49100.76},{"description":"Test iPad","laborCost":"25","quantity":180,"laborCostTotal":4500},{"description":"Aluminum fascia: Width: Install Fascia Metal - 3 Bend - Polaris, Color: Black","laborCost":"1500","units":"FT","quantity":1,"laborCostTotal":1500}]'
    items: string | null
    salespersonName: string | null
    themeColor: string | null
    timeZone: string | null
    totalLaborCost: string | null
    typeVersion: string | null
  }
  createdTime: string | null
  estimateId: number | null
  name: string | null
  pdfUrl: string | null
  type: ITypesLaborBill | null
  workflows: number[] | null
}

export type NewFinalLaborBillType = {
  info: {
    customerName: string | null
    customerAddressLine1: string | null
    customerAddressLine2: string | null
    customerNumber: string | null
    salespersonName: string | null
    projectNumber: string | null
    companyLogoDarkUrl: string | null
    projectType: string | null
    detailAreaOfWork: string | null
    detailPreferredTimeline: string | null
    detailAgeOfInsulation: string | null
    detailMoisture: string | null
    detailMold: string | null
    detailVentilation: string | null
    detailTrimAreaOfWork: string | null
    detailGutterAreaOfWork: string | null
    detailAgeOfSiding: string | null
    detailSidingAccess: string | null
    detailSidingProblems: string | null
    detailYearsInHome: string | null
    detailNumberOfStories: string | null
    detailDumpster: string | null
    detailGutterProblems: string | null
    detailAgeOfRoof: string | null
    detailRoofAccess: string | null
    detailSatelliteDish: string | null
    detailRoofingProblems: string | null
    detailLeakingArea: string | null
    detailIceDamArea: string | null
    workflowId: string | null
    createdAt: string | null
    items: {
      description: string | null
      unit: string | null
      quantity: number | null
      labor: number | null
    }[]
  }
  type: ITypesLaborBill | null
}
type FormatPriceOptions = {
  locales?: Intl.LocalesArgument
  options?: Intl.NumberFormatOptions
}

export const formatNumberPrice = (value: number | string | undefined | null, optionsParams?: FormatPriceOptions) => {
  const locale = optionsParams?.locales ?? 'en-US'
  const options = optionsParams?.options ?? ({ style: 'currency', currency: 'USD' } as FormatPriceOptions['options'])
  if (value === null || value === undefined) {
    return Number(0)?.toLocaleString(locale, options)
  }
  if (typeof value === 'string') {
    const onlyNumbers = value.replace(/[^0-9.]/g, '') // Elimina todo excepto números y puntos
    const numberPrimitive = parseFloat(onlyNumbers) // Convierte a número primitivo con decimales

    return numberPrimitive?.toLocaleString(locale, options)
  }
  return value?.toLocaleString(locale, options)
}
export const validateValuesObj = (obj: { [key: string]: any }) => {
  for (const key in obj) {
    if (obj[key] === 'null') obj[key] = ''
    obj[key] = obj[key] ?? ''
  }
  return obj
}
