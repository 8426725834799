<script setup lang="ts">
  import { IQueuePhoto } from '@/db/tables'

  type TPhotoList = {
    photos: IQueuePhoto[]
    showEditButton?: boolean
  }

  const { photos } = defineProps<TPhotoList>()

  const emit = defineEmits(['remove', 'editPhoto'])

  const handleRemoveMedia = (id: string) => {
    emit('remove', id)
  }
  const handleEditImage = (photo: IQueuePhoto) => {
    emit('editPhoto', photo)
  }
</script>

<template>
  <div
    v-if="photos && photos.length > 0"
    class="gap-3 flex-wrap overflow-y-scroll overflow-y-hidden w-full LayoutImagesPunchlistTask"
    style="overflow: hidden; white-space: nowrap; max-height: 280px"
  >
    <div v-for="(photo, index) in photos" :key="photo.id" class="relative w-full h-full">
      <div v-if="(photo.type_media || photo?.mediaType) === 'IMAGE'" class="w-full" style="height: 120px">
        <img :src="photo?.base64_annotation ?? photo?.base64 ?? photo.url" alt="media image" class="border-round-md w-full" style="object-fit: cover; height: 120px" />
        <Button
          style="top: 8px; right: 8px; width: 2rem"
          icon="pi pi-trash"
          severity="danger"
          class="absolute z-5 p-2 font-light text-xs text-black-alpha-50 border-red-200 text-white"
          @click="handleRemoveMedia(photo.id)"
        />
        <Button
          v-if="showEditButton"
          style="top: 45px; right: 8px; width: 2rem"
          icon="pi pi-pencil"
          class="absolute z- p-2 font-light text-xs text-black-alpha-50 border-200 text-white"
          @click="handleEditImage(photo)"
        />
      </div>
      <div v-if="photo.type_media === 'VIDEO'">
        <div class="w-full bg-black-alpha-90 border-round flex flex-column align-items-center justify-content-center" style="height: 120px">
          <div class="absolute top-0 left-0 flex flex-column align-items-center justify-content-center" style="width: 30px; height: 30px">
            <p class="text-white font-bold m-0">
              {{ index + 1 }}
            </p>
          </div>
          <Button
            style="top: 8px; right: 8px; width: 2rem"
            icon="pi pi-trash"
            severity="danger"
            class="absolute z-5 p-2 font-light text-xs text-black-alpha-50 border-red-200 text-white"
            @click="handleRemoveMedia(photo.id)"
          />
          <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_249_83)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.1568 8.52857C10.2048 8.12158 10.3472 7.73144 10.5726 7.3892C10.7981 7.04697 11.1003 6.76211 11.4553 6.55731C11.8102 6.35251 12.2081 6.23343 12.6172 6.20955C13.0263 6.18567 13.4354 6.25766 13.8118 6.41978C15.7145 7.23319 19.9787 9.1664 25.3895 12.2893C30.8021 15.4139 34.6094 18.1427 36.2631 19.3807C37.675 20.4396 37.6786 22.5394 36.2649 23.6019C34.6273 24.8327 30.8666 27.5256 25.3895 30.6897C19.907 33.8538 15.693 35.7637 13.8082 36.5664C12.1849 37.2597 10.3682 36.208 10.1568 34.4576C9.90952 32.4115 9.44727 27.7657 9.44727 21.4913C9.44727 15.2204 9.90772 10.5764 10.1568 8.52857Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_249_83">
                <rect width="43" height="43" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .LayoutImagesPunchlistTask {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
</style>
