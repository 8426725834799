<script lang="ts" setup>
  import { toRefs } from 'vue'
  // import { useImageUrls } from '@/composables/useImageUrls'
  // import { PhotoFormatIdx } from '@/constants/types'

  import 'lazysizes'
  import 'lazysizes/plugins/parent-fit/ls.parent-fit'

  const props = defineProps({
    url: {
      type: String,
      required: true,
    },
  })

  const { url } = toRefs(props)

  const addtime = (photo: string) => {
    if (!photo || photo.includes('base64')) {
      return photo
    }
    return photo
  }

  // function generateSrc() {
  //   if (url.value.includes('xx-temp')) {
  //     return url.value
  //   } else {
  //     return useImageUrls(url.value)[PhotoFormatIdx.md]
  //   }
  // }
</script>

<!-- :src="generateSrc()" -->

<template>
  <div style="width: 30px; height: 30px">
    <img :src="addtime(url)" alt="media image" class="h-full w-full border-round-md lazyload" style="object-fit: cover" />
  </div>
</template>
